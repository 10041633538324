import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { GraphService } from './services/graph.service';
import { LocationVersionService } from './services/location-version.service';
import { RoleService } from './services/role.service';
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSortModule } from '@angular/material/sort';
import { TrackerInputComponent } from './components/tracker-input/tracker-input.component';
import { TrackerSelectComponent } from './components/tracker-select/tracker-select.component';
import { LocationFilterComponent } from './components/location-filter/location-filter.component';
import { TrackerTableComponent } from './components/tracker-table/tracker-table.component';
import { TrackerCounterComponent } from './components/tracker-counter/tracker-counter.component';
import { AlertService } from './services/alert.service';
import { TableDataPipe } from './pipes/table-data.pipe';
import { TrackerVariableDirective } from './directives/tracker-variable.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectRemoveAriaOwnsDirective } from './directives/mat-select-remove-aria-owns.directive';
import { TrackerConfirmationDialogComponent } from './components/tracker-confirmation-dialog/tracker-confirmation-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatDividerModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatSortModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatChipsModule
  ],
  providers:[
    GraphService,
    LocationVersionService,
    RoleService,
    AlertService,
    DecimalPipe,
    TrackerVariableDirective,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}
  ],
  exports: [
    ReactiveFormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    MatInputModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatSortModule,
    MatTabsModule,
    MatSnackBarModule,
    MatChipsModule,
    TrackerInputComponent,
    TrackerSelectComponent,
    LocationFilterComponent,
    TrackerTableComponent,
    TrackerCounterComponent,
    TableDataPipe,
    TrackerVariableDirective
  ],
  declarations: [
    TrackerInputComponent,
    TrackerSelectComponent,
    LocationFilterComponent,
    TrackerTableComponent,
    TrackerCounterComponent,
    TableDataPipe,
    TrackerVariableDirective,
    MatSelectRemoveAriaOwnsDirective,
    TrackerConfirmationDialogComponent
  ]
})
export class ShareModule { }
