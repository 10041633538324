import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LineItemColumnDef } from '../../models/line-item-column.model';
import { Subscriptable } from '../../interfaces/subscriptable.interface';
import { HeaderColumnDef } from '../../models/header-column.model';
import { BusinessPlanLineItem } from 'src/app/features/businessplan/models/business-plan-line-items';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-tracker-table',
  templateUrl: './tracker-table.component.html',
  styleUrls: ['./tracker-table.component.css']
})
export class TrackerTableComponent<T extends Subscriptable> implements OnInit, AfterViewInit, OnChanges {

  @Input() data: T[] = [];
  @Input() total: T = {} as T;
  @Input() displayedColumns: LineItemColumnDef[] = [];
  @Input() headerColumns: HeaderColumnDef[] = []
  @Input() hasEdit: boolean = false;
  @Input() hasDelete: boolean = false;
  @Input() hasTotal: boolean = false;
  @Output() clickEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<number>();
  @Output() deleteEvent = new EventEmitter<number>();


  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>(this.data);
  @ViewChild(MatSort) sort!: MatSort;

  columnTitles = this.displayedColumns
    .map(l => l.label);

  headerTitles = this.headerColumns.map(l => l.label);
  hasHeader = this.headerColumns.length > 0;

  constructor() {}

  ngOnInit(): void {
    this.columnTitles = this.displayedColumns.map(l => l.label);
    this.headerTitles = this.headerColumns.map(l => l.label);
    this.hasHeader = this.headerColumns.length > 0;

    if (this.hasEdit || this.hasDelete) {
      this.columnTitles = this.columnTitles.concat(['actions']);
    }
    
    this.dataSource = new MatTableDataSource<T>(this.data);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource<T>(this.data);
    this.dataSource.sort = this.sort;
  }

  onSort(sort: Sort) {
  }

  onEdit(id: number) {
    this.editEvent.emit(id);
  }

  onDelete(id: number) {
    this.deleteEvent.emit(id);
  }

  onClick(lineItem: any) {
    this.clickEvent.emit(lineItem);
  }
}
