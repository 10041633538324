import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-tracker-select',
  templateUrl: './tracker-select.component.html',
  styleUrls: ['./tracker-select.component.css']
})
export class TrackerSelectComponent implements OnInit {
  @Input() appearance!: MatFormFieldAppearance
  @Input() options!: any[]
  @Input() labelName!: string;
  @Input() trackerFormControl!: FormControl;
  @Input() hasError!: boolean;
  @Input() errorMessage!: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
