import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppbarComponent } from './components/appbar/appbar.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { FallbackAvatarComponent } from './components/fallback-avatar/fallback-avatar.component';



@NgModule({
  declarations: [
    AppbarComponent,
    FallbackAvatarComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AppbarComponent,
    FallbackAvatarComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class CoreModule { }
