import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CalGuardService } from '@cvx/cal-angular';

const routes: Routes = [
  {
    path: '',
    title: 'eXplore Tracker | home',
    loadChildren: () => {
      console.log("Loading Home Module");
      return import('./features/home/home.module').then(m => m.HomeModule)
    }
  },
  {
    path: 'monthly-tracking',
    title: 'eXplore Tracker | monthly tracking',
    canActivate: [CalGuardService],
    loadChildren: () => {
      console.log("Loading Monthly Tracking Module");
      return import('./features/monthly-tracking/monthly-tracking.module').then(m => m.MonthlyTrackingModule)
    }
  },
  {
    path: 'business-plan',
    title: 'eXplore Tracker | business plan',
    canActivate: [CalGuardService],
    loadChildren: () => {
      console.log("Loading Business Plan Module");
      return import('./features/businessplan/business-plan.module').then(m => m.BusinessplanModule)
    }
  },
  {
    path: 'admin',
    title: 'eXplore Tracker | admin',
    canActivate: [CalGuardService],
    loadChildren: () => {
      console.log("Loading Admin Module");
      return import('./features/admin/admin.module').then(m => m.AdminModule)
    }
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
