import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-tracker-input',
  templateUrl: './tracker-input.component.html',
  styleUrls: ['./tracker-input.component.css']
})
export class TrackerInputComponent implements OnInit {
  @Input() appearance!: MatFormFieldAppearance;
  @Input() labelName!: string;
  @Input() trackerFormControlName!: string;
  @Input() trackerFormControl!: FormControl;
  @Input() type!: string;
  @Input() placeholder!: string;
  @Input() errorMessage!: string;
  @Input() hasError!: boolean

  constructor() { }

  ngOnInit(): void {
  }

}
