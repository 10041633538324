import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocationFilterNode } from '../models/location-filter-node';
import { LocationVersion } from '../models/location-version';
import { SelectedLocationState } from '../models/location-state';

@Injectable({
  providedIn: 'root'
})
export class LocationFilterStateService {

  locationFilterSubject = new BehaviorSubject<LocationFilterNode[]>([]);
  selectedLocations = new BehaviorSubject<SelectedLocationState>(new SelectedLocationState());
  
  get locationFilterState(): LocationFilterNode[] { return this.locationFilterSubject.value }

  constructor() { }

  initialize(lv: LocationVersion) {
    let tree = this.buildTree(lv);
    this.locationFilterSubject.next([tree]);
  }

  buildTree(lv: LocationVersion): LocationFilterNode {
    let root: LocationFilterNode = {
      id: lv.id,
      name: 'global',
      parentId: '',
      children: []
    }

    lv.regions.forEach( region => {      
      let regionNode: LocationFilterNode = {
        id: region.id,
        name: region.name,
        parentId: lv.id,
        children: []
      }

      region.businessUnits.forEach( bu => {      
        let businessUnitNode: LocationFilterNode = {
          id: bu.id,
          name: bu.name,
          parentId: region.id,
          children: []
        }

        bu.countries.forEach(country => {      
          let countryNode: LocationFilterNode = {
            id: country.id,
            name: country.name,
            parentId: bu.id,
            children: null
          }
          
          businessUnitNode.children?.push(countryNode);
        });

        regionNode.children?.push(businessUnitNode);
      });

      root.children?.push(regionNode);
    });

    return root;
  }
}
