import { Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { fromFetch } from 'rxjs/fetch';
import { Observable, catchError, from, map, mergeMap, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GraphResponse } from '../models/graph-response';
import { GraphUser } from '../models/graph-user';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor(
    private calService: CalAngularService
  ) { }

  getProfilePicture() {
    let graphPictureEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";
    return this.sendGraphRequest(graphPictureEndpoint);
  }

  getFallbackPicture() {
    let graphFallbackPictureEndpoint = "https://graph.microsoft.com/beta/me/photo/$value";
    return this.sendGraphRequest(graphFallbackPictureEndpoint);
  }

  searchUser(searchStr: string) {
    let search = `$search=(\"displayName:${searchStr}\" OR \"mail:${searchStr}\" OR \"userPrincipalName:${searchStr}\" OR \"givenName:${searchStr}\" OR \"surName:${searchStr}\" OR \"otherMails:${searchStr}\")`
    let users = `https://graph.microsoft.com/v1.0/users?${search}`
    return this.sendGraphRequest(users);
  }

  private sendGraphRequest(resource: string) {
    let token$ = this.calService.getAADToken(environment.oAuthSettings.graphScopes, false);

    return token$.pipe(
      mergeMap(token => 
        fromFetch(resource, {
          headers: {
            'ConsistencyLevel': 'eventual',
            'Authorization': `Bearer ${token}`
          }
        })
      ),
    );
  }
}
