import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tracker-counter',
  templateUrl: './tracker-counter.component.html',
  styleUrls: ['./tracker-counter.component.css']
})
export class TrackerCounterComponent implements OnInit {
  @Input() title!: string
  @Input() value!: number

  constructor() { }

  ngOnInit(): void {
  }

}
