<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <p>{{data.message}}</p>
</mat-dialog-content>

<div mat-dialog-actions class="gap-2">
  <button type="button" class="cvx-button" (click)="onAccept()">accept</button>
  <button type="button" class="cvx-button-alt" (click)="onCancel()">cancel</button>
</div>
