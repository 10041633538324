import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common'
@Pipe({
  name: 'tableData'
})
export class TableDataPipe implements PipeTransform {

  constructor(
    private decimalPipe: DecimalPipe,
  ) {}

  transform(value: any, precision: number = 2): any {
    switch (typeof(value)) {
      case 'number':
        return this.handle_number(value, precision);
      case 'boolean':
        return this.handle_boolean(value);
      case 'string':
        return this.handle_string(value);
      case 'object':
        return value
      case null:
        return value
      default:
        console.warn(`Unhandled table data type: ${typeof(value)} with value ${value}`);
        return value
    }
  }

  private handle_number(value: number, precision: number): string | null {

    if (Number.isInteger(value)) {
      return value.toString();
    }

    return this.decimalPipe.transform(value, `1.${precision}-${precision}`)
  }

  private handle_boolean(value: boolean): string {
    return value ? 'yes' : 'no'
  }

  private handle_string(value: string): string {
    return value.toLowerCase();
  }
}
