const APP_URL = "https://tracker-dev.azure.chevron.com"

export const environment = {
  production: false,
  trackerUrl: APP_URL,
  oAuthSettings: {
    appId: '802c8433-5f4d-4800-bd23-271476789dbe',
    authority: 'https://login.microsoftonline.com/',
    tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    redirectUri: APP_URL,
    postLogoutRedirectUri: APP_URL,
    oidcScopes: [
        'user.read',
        'openid',
        'profile',
        'offline_access'
    ],
    graphScopes: ["User.Read", "Directory.Read.All", "User.Read.All"],
    apiScope: 'https://etra-test.azure.chevron.com/user_impersonation'
  }
};