import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationFilterFlatNode } from '../../models/location-filter-flat-node';
import { LocationFilterNode } from '../../models/location-filter-node';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { LocationFilterStateService } from '../../services/location-filter-state.service';
import { LocationType } from '../../enums/location.enum';
import { SelectedLocationState } from '../../models/location-state';

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.css']
})
export class LocationFilterComponent implements OnInit {
  @Output() refresh = new EventEmitter<any>();

  flatNodeMap = new Map<LocationFilterFlatNode, LocationFilterNode>();
  nestedNodeMap = new Map<LocationFilterNode, LocationFilterFlatNode>();

  selectedParent: LocationFilterFlatNode | null = null;

  treeControl!: FlatTreeControl<LocationFilterFlatNode>;
  treeFlattener!: MatTreeFlattener<LocationFilterNode, LocationFilterFlatNode>;
  dataSource!: MatTreeFlatDataSource<LocationFilterNode, LocationFilterFlatNode>;

  checklistSelection = new SelectionModel<LocationFilterFlatNode>(false);

  constructor(private locationStateService: LocationFilterStateService) { 
    this.treeFlattener = new MatTreeFlattener(
      this.transformer, 
      this.getLevel, 
      this.isExpandable, 
      this.getChildren);

    this.treeControl = new FlatTreeControl<LocationFilterFlatNode>(
      this.getLevel, 
      this.isExpandable);

    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    locationStateService.locationFilterSubject.subscribe(tree => {
      this.dataSource.data = tree
    });
  }

  getLevel = (node: LocationFilterFlatNode) => node.level;
  isExpandable = (node: LocationFilterFlatNode) => node.expandable;
  getChildren = (node: LocationFilterNode) => node.children;
  hasChildren = (_: number, node: LocationFilterFlatNode) => node.expandable;

  transformer = (node: LocationFilterNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.id === node.id
      ? existingNode
      : {} as LocationFilterFlatNode;

    flatNode.id = node.id;
    flatNode.parentId = node.parentId;
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = Array.isArray(node.children) && !!node.children.length;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);

    return flatNode;
  }

  descendantsAllSelected(node: LocationFilterFlatNode) {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  descendantsPartiallySelected(node: LocationFilterFlatNode) {
    const descendants = this.treeControl.getDescendants(node);
    const results = descendants.some(child => this.checklistSelection.isSelected(child));
    return results && !this.descendantsAllSelected(node)
  }

  locationSelectionToggle(node: LocationFilterFlatNode): void {
    this.checklistSelection.toggle(node);

    switch (node.level) {
      case LocationType.Global:
        this.clearLocationFilter();
        break;
      case LocationType.Region:
      case LocationType.BusinessUnit:
      case LocationType.Country:
        //this.toggleLocation(node);
        this.updateFilterState(node)
        break;
      default:
        console.warn(`location type value ${node.level} not supported`);
        break;
    }

    this.refresh.emit();
  }

  private updateFilterState(node: LocationFilterFlatNode) {
    let state: SelectedLocationState = {
      regions: [],
      businessUnits: [],
      countries: []
    };

    let parent = this.getParentNode(node);
    let grandParent = null;

    if (parent) {
      grandParent = this.getParentNode(parent);
    }

    switch (node.level) {
      case LocationType.Region:
        state.regions.push(node.name);
        break;
      case LocationType.BusinessUnit:
        if (parent) {  
          state.regions.push(parent.name);
        }

        state.businessUnits.push(node.name);
        break;
      case LocationType.Country:
        if (grandParent) {
          state.regions.push(grandParent.name);
        }

        if (parent) {
          state.businessUnits.push(parent.name);
        }

        state.countries.push(node.name);
        break;
      default:
        console.warn(`Location level ${node.level} is not supported`);
    }

    console.log(state);
    this.locationStateService.selectedLocations.next(state);
  }

  private getParentNode(node: LocationFilterFlatNode) {
    let parent = this.treeControl.dataNodes.find(n => n.id === node.parentId);
    if (parent)
      return parent;

      return null;
  }

  private clearLocationFilter() {
    let state = this.locationStateService.selectedLocations.value;
    state.regions = []
    state.businessUnits = []
    state.countries = []
    this.locationStateService.selectedLocations.next(state)
  }

  ngOnInit(): void {
  }

}
