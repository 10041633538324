import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { Observable, catchError, of, tap } from 'rxjs';
import { GraphService } from './share/services/graph.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('mobileNav') 
  mobileNav!: ElementRef;

  showMobileNav: boolean = false;
  useFallbackAvatar: boolean = false;

  picture$: Observable<any> = 
    this.graphService.getFallbackPicture()
      .pipe(
        tap(resp => {
          if (!resp.ok)
            this.useFallbackAvatar = true;
        }),
        catchError(err => {
          console.error(err);
          this.useFallbackAvatar = true;
          return of()
        })
      );


  constructor(
    private calService: CalAngularService,
    private graphService: GraphService,
  ) {
  }

  title = 'eXplore tracker | home';

  ngOnInit(): void {
    this.calService.isUserSignedIn().subscribe((signedIn: boolean) => {
      if (signedIn) {
        let user = this.calService.cvxClaimsPrincipal;
        console.log("User %s logged in", user.email);
      }
    })
  }

  public toggleMobileNav() {
    this.showMobileNav = !this.showMobileNav;
    console.log("Mobile Nav State: ", this.showMobileNav);
  }
}
