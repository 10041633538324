import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { CalAngularModule } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { ShareModule } from './share/share.module';



@NgModule({
  declarations: [
    AppComponent,
    //BusinessPlanSummaryComponent,
    
  ],
  imports: [
    BrowserModule, 
    AppRoutingModule,
    HttpClientModule, 
    BrowserAnimationsModule, 
    CoreModule,
    ShareModule,
    CalAngularModule.forRoot({
      cacheLocation: "localStorage",
      autoSignIn: true,
      popupForLogin: false,
      instance: environment.oAuthSettings.authority,
      tenantId: environment.oAuthSettings.tenantId,
      clientId: environment.oAuthSettings.appId,
      redirectUri: environment.oAuthSettings.redirectUri,
      oidcScopes: environment.oAuthSettings.oidcScopes,
      graphScopes: environment.oAuthSettings.graphScopes
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
