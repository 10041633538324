import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogData } from '../../models/confirmation-dialog-data';

@Component({
  selector: 'app-tracker-confirmation-dialog',
  templateUrl: './tracker-confirmation-dialog.component.html',
  styleUrls: ['./tracker-confirmation-dialog.component.css']
})
export class TrackerConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TrackerConfirmationDialogComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {}

  onAccept() {
    this.dialogRef.close({'accept': true});
  }

  onCancel() {
    this.dialogRef.close({'accept': false});
  }
}
