<div class="sidebar flex sm:flex-row md:flex-col">
  <div class="flex sm:flex-row md:flex-col sidebar-container">

    <!-- Hallmark -->
    <a id="chevronHallmark" class="flex flex-col justify-center items-center" href="{{this.trackerUrl}}">
      <img src="assets/images/cvx_logo.png"
        alt="Chevron" />
    </a>

    <!-- Hub Link -->
    <a
      class="toolbar-link flex flex-col justify-center items-center"
      href="https://chevron.sharepoint.com/sites/explorehub/SitePages/Home.aspx"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="assets/images/explore_hub_icon_blue.png" 
        alt="Explore Hub"/>
      <p>Hub</p>
    </a>

    <!-- Prospect Link -->
    <a
      class="toolbar-link flex flex-col justify-center items-center"
      href="http://eprosp.azure.chevron.com/"
      target="_blank"
      rel="noopener noreferrer"
      ><img src="assets/images/prospect_icon_blue.png" 
        alt="Explore Prospect" />
      <p>prospect</p>
    </a>

    <!-- Maps Link -->
    <a
      class="toolbar-link flex flex-col justify-center items-center"
      href="https://chevron.sharepoint.com/sites/explorehub/SitePages/ToolLaunch.aspx?app=maps"
      target="_blank"
      rel="noopener noreferrer"
      ><img src="assets/images/explore_maps_icon_blue.png" 
        alt="Explore Maps" />
      <p>maps</p>
    </a>

    <!-- Docs Link -->
    <a
      class="toolbar-link flex flex-col justify-center items-center"
      href="https://chevron.sharepoint.com/sites/explorehub/SitePages/ToolLaunch.aspx?app=docs"
      target="_blank"
      rel="noopener noreferrer"
      ><img src="assets/images/explore_docs_icon_blue.png" 
        alt="Explore Docs" />
      <p>docs</p>
    </a>

    <!-- Analogs Link -->
    <div class="toolbar-link flex flex-col justify-center items-center tooltip-custom">
      <img src="assets/images/explore_analog_icon_blue.png" 
        alt="Explore Analogs"/>
      <p>analogs</p>
      <!--
      <div class="tooltip-text tooltip-text-right" id="analogLinks">
        <a
          href="https://chevron.sharepoint.com/sites/explorehub/SitePages/ToolLaunch.aspx?app=analogs"
          target="_blank"
          rel="noopener noreferrer"
          >analysis toolbox ></a
        >
        <br />
        <a
          href="https://chevron.sharepoint.com/sites/explorehub/SitePages/ToolLaunch.aspx?app=analogsRS"
          target="_blank"
          rel="noopener noreferrer"
          >rapid search toolbox ></a
        >
        <br />
        <a
          href="https://chevron.sharepoint.com/sites/explorehub/SitePages/ToolLaunch.aspx?app=analogsQA"
          target="_blank"
          rel="noopener noreferrer"
          >smart Q&A ></a
        >
        <br />
        <a
          href="https://chevron.sharepoint.com/sites/explorehub/SitePages/ToolLaunch.aspx?app=analogsRAC"
          target="_blank"
          rel="noopener noreferrer"
          >smart RAC ></a
        >
        <br />
      </div>
    -->
    </div>

    <!-- EXCAP Link -->
    <a
      class="toolbar-link flex flex-col justify-center items-center"
      href="https://chevron.sharepoint.com/sites/explorehub/SitePages/ToolLaunch.aspx?app=excap"
      target="_blank"
      rel="noopener noreferrer"
      ><img src="assets/images/explore_compepitor_icon_blue.png" 
        alt="ExCAP"/>
      <p>EXCAP</p>
    </a>

    <!-- FEAST Link -->
    <a
      class="toolbar-link flex flex-col justify-center items-center"
      href="https://feast.azure.chevron.com/"
      target="_blank"
      rel="noopener noreferrer"
      ><img src="assets/images/explore_feast_icon_blue.png" 
        alt="FEAST"/>
      <p>FEAST</p>
    </a>

    <!-- Basins Link -->
    <a
      class="toolbar-link flex flex-col justify-center items-center"
      href="https://chevron.sharepoint.com/sites/explorehub/SitePages/ToolLaunch.aspx?app=basin"
      target="_blank"
      rel="noopener noreferrer"
      ><img src="assets/images/explore_basin_icon_blue.png" 
        alt="Explore Basins"/>
      <p>basins</p>
    </a>

    <!-- Tracker Link -->
    <a
      id="toolbarTracker"
      class="toolbar-link flex flex-col justify-center items-center"
      href="{{this.trackerUrl}}"
      target="_blank"
      rel="noopener noreferrer"
      ><img src="assets/images/explore_tracker_icon_blue.png" 
        alt="Explore Tracker"/>
      <p>tracker</p>
    </a>

    <!-- Search Link-->
    <a 
      class="toolbar-link flex flex-col justify-center items-center"
      href="https://chevron.sharepoint.com/sites/eXplSuperSearchSupport"
      target="_blank" rel="noopener noreferrer">
      <img src="assets/images/super_search_icon.png"
        alt="Super Search" />
      <p>search</p>
    </a>
  </div>
</div>
