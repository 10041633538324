<main class="app-layout">
    <!-- Side Bar -->
    <app-appbar class="app-sidebar"></app-appbar>

    <!-- Main Nav -->
    <nav class="flex flex-row justify-between items-center bg-white app-nav">
      <!-- Logo -->
      <div>
        <a href="/" class="flex flex-grow items-center py-4 px-2">
          <img src="assets/images/exploration-icon.png" alt="">
          <span class="font-header text-lg font-bold text-cvx-light-blue">eXplore tracker</span>
        </a>
      </div>

      <!-- Primary Route Container -->
      <div class="hidden md:flex flex-grow justify-center items-center space-x-1">
        <a class="py-4 px-2 text-lg font-semibold font-header text-cvx-dark-blue hover:text-cvx-light-blue hover:underline" routerLinkActive="text-cvx-light-blue" routerLink="/monthly-tracking">monthly tracking</a>
        <a class="py-4 px-2 text-lg font-semibold font-header text-cvx-dark-blue hover:text-cvx-light-blue hover:underline" routerLinkActive="text-cvx-light-blue" routerLink="/business-plan">business plan</a>
        <a class="py-4 px-2 text-lg font-semibold font-header text-cvx-dark-blue hover:text-cvx-light-blue hover:underline" routerLinkActive="text-cvx-light-blue" routerLink="/admin">admin</a>
      </div>

      <div class="flex flex-row justify-between items-center m-4">
        
        <section class="flex flex-row font-header text-cvx-light-blue items-center justify-evenly">
          <a class="m-2" href="https://forms.office.com/Pages/ResponsePage.aspx?id=oZ15_cG_NEKpHHKzocueJqwPZLbAzPZJhYB1GDb5N_pUOEFQQVdORFNPWUNRSFU0VThZOEJJSkxYUi4u">feedback</a>
          <span class="font-medium text-cvx-light-blue">|</span>
          <a class="m-2" href="https://chevron.sharepoint.com/sites/eXplTrackerSupport">support</a>
        </section>
        
        <!-- Profile Settings -->
        <div>
          <img *ngIf="!useFallbackAvatar" [src]="(picture$ | async)" alt="">
          <app-fallback-avatar *ngIf="useFallbackAvatar"></app-fallback-avatar>
        </div>
      </div>
      
          
    </nav>

    <!-- Main Content-->
    <section class="app-content">
      <router-outlet></router-outlet>
    </section>
</main>
