import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appbar',
  templateUrl: './appbar.component.html',
  styleUrls: ['./appbar.component.css']
})
export class AppbarComponent implements OnInit {

  trackerUrl: string;
  
  constructor() { 
    this.trackerUrl = environment.trackerUrl
  }

  ngOnInit(): void {
  }

}
