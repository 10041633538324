export const api = {
  v1: {
    users: 'api/v1/users',
    grants: `api/v1/grants`,
    countries: 'api/v1/countries',
    businessUnits: 'api/v1/business-units',
    regions: 'api/v1/regions',
    locationVersions: 'api/v1/location-versions',
    roles: 'api/v1/roles',
    permissions: 'api/v1/permissions',
    expenditureCategories: 'api/v1/types/expenditure-categories',
    labels: 'api/v1/types/budget-labels',
    lineItemTypes: 'api/v1/types/line-item-types',
    coreBaseBuild: 'api/v1/types/core-base-build',
    types: {
      wellStatuses: 'api/v1/types/well-statuses',
      accountingStatuses: 'api/v1/types/accounting-statuses',
      leadershipTrackingStatuses: 'api/v1/types/leadership-tracking-statuses',
      hydroCarbonTypes: 'api/v1/types/hydro-carbon-types'
    },
    businessPlan: {
      businessPlan: 'api/v1/business-plans',
      lineItems: 'api/v1/business-plan/line-items',
    },
    monthlyTracking: {
      lineItems: 'api/v1/monthly-tracking/line-items',
      actuals: 'api/v1/monthly-tracking/actuals',
      outlooks: 'api/v1/monthly-tracking/outlooks',
      forecasts: 'api/v1/monthly-tracking/forecasts',
      categorized_line_items: 'api/v1/monthly-tracking/categorized-line-items',
      leadership_tracking: 'api/v1/monthly-tracking/leadership-tracking',
      wells: 'api/v1/monthly-tracking/wells',
      summary: 'api/v1/monthy-tracking/summaries' 
    }
  }
}
