import { AfterViewInit, Directive, ElementRef } from '@angular/core';

// TODO: Remove after ITFP implements Edge browser fix
/**
 * Temporary code to fix Chrome browser crashing.
 * 
 * Details: https://issuetracker.google.com/issues/335553723
 */
@Directive({
  selector: 'mat-select'
})
export class MatSelectRemoveAriaOwnsDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.removeAriaOwns();
  }

  private removeAriaOwns(): void {
    const parentElement = this.elementRef.nativeElement.parentElement;
    const labelElement = parentElement.querySelector('span > label');

    if (labelElement) {
      labelElement.removeAttribute('aria-owns');
      labelElement.removeAttribute('aria-labelledby');
    }
  }
}
