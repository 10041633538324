import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, mergeMap } from 'rxjs';
import { CalAngularService } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private calService: CalAngularService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let scopes = [
      environment.oAuthSettings.apiScope
    ]

    return this.calService.getAADToken(scopes).pipe(
      mergeMap(token => {
        let requestWithToken = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(requestWithToken);
      }));
  }
}
