import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fallback-avatar',
  templateUrl: './fallback-avatar.component.html',
  styleUrls: ['./fallback-avatar.component.css']
})
export class FallbackAvatarComponent implements OnInit {

  public color: string = 'cvx-dark-blue';

  constructor() { }

  ngOnInit(): void {
    let colors = ['cvx-dark-blue', 'cvx-dark-teal', 'cvx-dark-green', 'cvx-dark-orange', 'cvx-dark-red', 'cvx-dark-purple']
    this.color = colors[Math.floor(Math.random() * colors.length)];
  }

}
