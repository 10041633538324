<!-- Table -->
<table mat-table matSort 
  class="table-container" 
  [dataSource]="dataSource"
  (matSortChange)="onSort($event)">

  <!-- Secondary Header -->
  <ng-container *ngIf="hasHeader">
    <ng-container [matColumnDef]="head.label" *ngFor="let head of headerColumns">
      <th scope="col" class="bg-orange mat-header-cell" mat-header-cell *matHeaderCellDef  [attr.colspan]="head.span"> 
          {{head.label}} 
      </th>
    </ng-container>
  </ng-container>

  <!-- Actions -->
  <ng-container *ngIf="hasEdit || hasDelete" matColumnDef="actions">
    <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>actions</th>
    <td mat-cell class="mat-cell" *matCellDef="let lineItem">
      <div>
        <button *ngIf="hasEdit" (click)="onEdit(lineItem.id)"><mat-icon>edit</mat-icon></button>
        <button *ngIf="hasDelete" (click)="onDelete(lineItem.id)"><mat-icon>delete</mat-icon></button>
      </div>
    </td>
  </ng-container>

  <!-- Data -->
  <ng-container *ngFor="let colDef of displayedColumns" [sticky]="colDef.sticky ?? false" matColumnDef="{{colDef.label}}">
    <th mat-header-cell class="mat-header-cell" mat-sort-header sortActionDescription="sort by {{colDef.label}}" *matHeaderCellDef>{{colDef.label}}</th>
    <td mat-cell class="mat-cell"  *matCellDef="let lineItem">
      {{ lineItem[colDef.key] }}
    </td>
    
    <ng-container *ngIf="hasTotal">
      <td mat-footer-cell class="mat-cell" *matFooterCellDef>{{ total[colDef.key] }}</td>
    </ng-container>
  </ng-container>

  <!-- Header Row -->
  <ng-container *ngIf="hasHeader">
    <tr mat-header-row class="mat-row" *matHeaderRowDef="headerTitles; sticky: true"></tr>
  </ng-container>

  <!-- Data Rows -->
  <tr mat-header-row class="mat-row" *matHeaderRowDef="columnTitles; sticky: true"></tr>
  <tr mat-row class="mat-row tracker-data-row" (click)="onClick(row)" *matRowDef="let row; columns: columnTitles"></tr>

  <!-- Footer Row -->
  <ng-container *ngIf="hasTotal">
    <tr mat-footer-row class="mat-row" *matFooterRowDef="columnTitles"></tr>
  </ng-container>
</table>