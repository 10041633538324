<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- Leaf Node -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled>''</button>
    <mat-checkbox 
      [checked]="checklistSelection.isSelected(node)"
      (change)="locationSelectionToggle(node)">{{node.name}}</mat-checkbox>
  </mat-tree-node>

  <!-- Parent Node -->
  <mat-tree-node *matTreeNodeDef="let node; when: hasChildren" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle
      attr.aria-label="toggle {{node.name}}">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>

    <mat-checkbox 
      [checked]="checklistSelection.isSelected(node)"
      [indeterminate]="descendantsPartiallySelected(node)"
      (change)="locationSelectionToggle(node)">{{ node.name }}</mat-checkbox>
  </mat-tree-node>
</mat-tree>