import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from 'src/api.config';
import { environment } from 'src/environments/environment';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) { }

  public listRoles() {
    let resource = `${environment.trackerUrl}/${api.v1.roles}`
    return this.http.get<Role[]>(resource);
  }

  
}
