import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appVar]'
})
export class TrackerVariableDirective {
  @Input() set appVar(context: any) {
    this.context.$implicit = this.context.appVar = context;

    if (!this.hasView) {
      this.vcRef.createEmbeddedView(this.templateRef, this.context);
      this.hasView = true;
    }
  }

  private context: {
    $implicit: unknown;
    appVar: unknown;
  } = {
    $implicit: null,
    appVar: null
  };

  private hasView: boolean = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef
  ) { }

}
