import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from 'src/api.config';
import { environment } from 'src/environments/environment';
import { LocationVersion } from '../models/location-version';
import { CreateLocationVersionRequest } from '../requests/create-location-version-request';
import { ListLocationVersionRequest } from '../requests/list-location-version-request';

@Injectable({
  providedIn: 'root'
})
export class LocationVersionService {

  constructor(
    private http: HttpClient
  ) { }

  public listLocationVersions(req: ListLocationVersionRequest) {
    let resource = `${environment.trackerUrl}/${api.v1.locationVersions}`;
    let params = new HttpParams();
    params = params.append('current', req.current);
    params = params.append('desc', req.desc);

    return this.http.get<LocationVersion[]>(resource, {params: params})
  } 

  public getLocationVersion(year: number) {
    let resource = `${environment.trackerUrl}/${api.v1.locationVersions}/${year}`;
    return this.http.get<LocationVersion>(resource);
  }

  public createLocationVersion(locationVersion: CreateLocationVersionRequest) {
    let resource = `${environment.trackerUrl}/${api.v1.locationVersions}`;
    return this.http.post<LocationVersion>(resource, locationVersion);
  }

  public deleteLocationVersion(id: string) {
    let resource = `${environment.trackerUrl}/${api.v1.locationVersions}/${id}`;
    return this.http.delete(resource);
  }
}
